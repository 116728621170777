.facepile img {
  display: inline;
  max-width: 50px;

  &.left {
    margin-right: auto;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }
}

.card img {
  display: inline;
  max-width: 50px;

  &.left {
    margin-right: auto;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
  }
}        
 
